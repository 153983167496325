<!-- 首页轮播图 -->
<template>
    <div class='homeimgBox'>
        <div class="m-header">
            <div class="header"><img class="header-logo" src="../../assets/image/wjlogo.png" />旺家·东泰共建数字社区</div>
            <!-- 返回 刷新 -->
			<rightNav></rightNav>
            <!-- 时间 -->
			<div class="time-box">
				<Date></Date>
			</div>
            <!-- 内页标题 -->
            <div class="page-title">我们的文创产品助销</div>
        </div>
        <div class="hb-mainbox">
            <div class="hb-addbtn">
                <div class="select_box">
                    <el-select
                        style="width: 16vw"
                        :popper-append-to-body="false"
                        v-model="branchId"
                        placeholder="请选择所属站点"
                        @change="changeSelect"
                    >
                        <el-option
                            v-for="item in dataOptions"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
                
                <el-button type="primary" @click="addImg">+ 新增</el-button>
            </div>
            <div class="hb-list hb-header">
                <div class="hbh-item">图片</div><div class="hbh-item">发布状态</div>
                <div class="hbh-item">排序</div><div class="hbh-item">创建时间</div>
                <div class="hbh-item">操作</div>
            </div>
            <div class="hb-box">
                <div class="hblistBox" v-if="listData&&listData.length>0">
                    <div class="hb-list" v-for="(item,i) in listData" :key="i">
                        <div class="hbh-item"><img :src="item.dataDetailed?item.dataDetailed:''" /></div>
                        <div class="hbh-item">
                            <el-switch :disabled="!idEditpass" v-model="item.dataField1" active-color="#24D8D7" inactive-color="#002953" @change="swithChange(item)"></el-switch>
                        </div>
                        <div class="hbh-item">{{item.sort?item.sort:''}}</div>
                        <div class="hbh-item">{{item.createTime?item.createTime:''}}</div>
                        <div class="hbh-item">
                            <span class="hbh-del" @click="delData(item)">删除</span>
                            <span>|</span>
                            <span class="hbh-update" @click="updateData(item)">编辑</span>
                        </div>
                    </div>
                </div>
                <!-- <div v-else>
                    <div class="hbl-empty">暂无数据</div>
                </div> -->
            </div>
            <div class="pagination">
                <el-pagination
                    @current-change="pageChange"
                    layout="prev, pager, next"
                    prev-text="上一页"
                    next-text="下一页"
                    :current-page="queryData.pageNum"
                    :page-size="queryData.pageSize"
                    :total="queryData.total">
                </el-pagination>
            </div>
        </div>
        <!-- 删除 -->
        <dialogAlert :dialogVisible="dialogVisible" :dialogData="dialogData" @closeDialog="closeDialog" @determine="determine">
            <span slot="txt">{{delDataTxt}}</span>
        </dialogAlert>
        <!-- 新增编辑 -->
        <dialogAlert :dialogVisible="addDialogvisible" dialogTitle="我们的文创产品助销" @closeDialog="closeDialog" @determine="addHomeimg">
            <div class="homeimgbox">
                <div class="hi-item">
                    <div class="hi-txt">图片</div>
                    <div>
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadAction"
                            :show-file-list="false"
                            :on-error="imgerror"
                            :on-success="imgsuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl" :src="imageUrl" >
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="uploaderTips">建议上传333*254</div>
                    </div>
                </div>
                <div class="hi-item">
                    <div class="hi-txt">排序</div>
                    <div class="hi-right">
                        <!-- <el-input class="hi-input" v-model="addDatas.sort"></el-input> -->
                        <el-input-number  class="hi-input" v-model="addDatas.sort" :min="1" :max="100" :step="1" step-strictly label="排序"></el-input-number>
                        <span class="hi-tips">数字小的在前</span>
                    </div>
                </div>
                <div class="hi-item">
                    <div class="hi-txt">所属站点</div>
                    <div class="hi-right select_box">
                        <el-select v-model="addDatas.dataTyp" placeholder="请选择">
                            <el-option
                                v-for="item in dataOptions"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="hi-item">
                    <div class="hi-txt">是否发布</div>
                    <div class="hi-right">
                        <el-switch v-model="addDatas.dataField1" active-color="#24D8D7" inactive-color="#002953"></el-switch>
                    </div>
                </div>
            </div>
        </dialogAlert>
    </div>
</template>

<script>
import rightNav from '@/views/commonComponents/rightNav.vue';
import Date from '@/views/commonComponents/date.vue';
import dialogAlert from '@/views/homeImg/components/dialogAlert.vue';
import { listData, deleteDataDetail, postDataDetail, putDataDetail } from "@/api/dtdata";
import { getculturalList} from '@/api/home.js'
export default {
    components: {
        rightNav,
        Date,
        dialogAlert
    },
    data() {
        return {
            value1: true,
            dialogVisible: false,// 删除的弹窗
            dialogData: '',// 删除的数据
            delDataTxt: '',//删除的提示
            addDialogvisible: false,// 添加的弹窗
            listData: [],// 列表数据
            queryData:{
                pageNum: 1,
                pageSize: 12,
                total: 0,
            },

            addDatas: {
                dataModule: 'wjdt_cultural_banner_data',
                dataTyp: 'home_banner',
                dataId: undefined,
                dataDetailed: '',// 图片路径
                sort: '',// 排序
                dataField1: true,//发布状态
            },
            imageUrl: '',
            loading: '',
            imgnum: '',
            // uploadAction: 'http://192.168.2.10:8060/prod-api/common/upload'
            uploadAction: '/dev-api/common/upload',
            idEditpass: false,
            dataOptions: [],
            branchId: '',
            siteValue: '',
            siteOptions: [],//选择
        };
    },
    methods: {
        // 助销
        getculturalList(dataId){
            getculturalList().then(res=>{
                if(res.code == 200){
                    this.dataOptions = res.rows
                    this.queryData.pageNum = 1
                    this.$nextTick(()=>{
                        if(this.dataOptions.length>0){
                            this.branchId = this.dataOptions[0].id
                            if(this.$route.query.sel){
                                this.branchId = this.$route.query.sel*1
                            }
                            this.getData()
                        }
                    })
                }
            })
            // .finally(()=>{
            //     this.loading.close();
            // })
        },
        // 选择
        changeSelect(val) {
            this.queryData.pageNum = 1
            this.$router.replace({query:{sel:val}})
            this.getData()
        },
        swithChange(data){
            let loadings = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let newData = data
            putDataDetail(newData).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    setTimeout(() => {
                        //  this.queryData.pageNum = 1
                        this.getData()
                    }, 400);
                }
            }).finally(()=>{
                loadings.close();
            })
        },
        getData(){
            this.loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            listData({
                dataModule: 'wjdt_cultural_banner_data',
                dataTyp: this.branchId,
                pageNum: this.queryData.pageNum,
                pageSize: this.queryData.pageSize
            }).then(res=>{
                if(res.code == 200){
                    this.queryData.total = res.total
                    this.listData = []
                    res.rows.forEach(item => {
                        item.dataField1 = (item.dataField1&&item.dataField1!='false')?true:false
                        this.listData.push(item)
                    });
                    
                }
            }).finally(()=>{
                this.loading.close();
            })
        },
        pageChange(datenum){
            this.queryData.pageNum = datenum
            this.getData()
        },
        // 添加
        addImg(){
            if(this.idEditpass){
                this.imageUrl = ''
                this.addDatas.dataTyp = this.branchId*1
                this.addDatas.dataId = undefined
                this.addDatas.dataDetailed= ''
                this.addDatas.sort = 1// 排序
                this.addDatas.dataField1= true// 发布状态
                this.addDialogvisible = true
            }
            
        },
        // 编辑
        updateData(datas){
            if(this.idEditpass){
                this.imageUrl = datas.dataDetailed
                this.addDatas.dataTyp = datas.dataTyp*1
                this.addDatas.dataId = datas.dataId
                this.addDatas.dataDetailed= datas.dataDetailed// 图片路径
                this.addDatas.sort= datas.sort// 排序
                this.addDatas.dataField1= (datas.dataField1||datas.dataField1=='true')?true:false// 发布状态
                this.addDialogvisible = true
            }
            
        },
        // 删除
        delData(datas){
            if(this.idEditpass){
                this.delDataTxt = '您确定要删除吗？'
                this.dialogData = datas
                this.dialogVisible = true
            }
        },
        closeDialog(){
            this.dialogVisible = false
            this.addDialogvisible = false
        },
        // 确定删除
        determine(datas){
            deleteDataDetail(datas.dataId).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.queryData.pageNum = 1
                    this.getData()
                }
            }).finally(()=>{
                this.dialogVisible = false
            })
            
        },
        // 确定添加
        addHomeimg(){
            if(this.addDatas.dataDetailed){
                if(this.addDatas.dataTyp){
                     if(this.addDatas.dataId){
                        // 修改
                        putDataDetail(this.addDatas).then(res=>{
                            if(res.code == 200){
                                this.$message({
                                    message: '修改成功',
                                    type: 'success'
                                });
                                // this.queryData.pageNum = 1
                                this.getData()
                            }
                        })
                    }else{
                        // 添加
                        postDataDetail(this.addDatas).then(res=>{
                            if(res.code == 200){
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });
                                this.queryData.pageNum = 1
                                this.getData()
                            }
                        })
                    }
                    this.addDialogvisible = false
                }else{
                    this.$message({
                        message:'请选择站点',
                        type: 'info'
                    })
                }

            }else{
                this.$message({
                    message:'请上传图片',
                    type: 'info'
                })
            }
            
        },
        // 图片上传成功
        imgsuccess(res, file){
            if(res.code == 200){
                this.imageUrl = URL.createObjectURL(file.raw);
                this.addDatas.dataDetailed = res.fileName
            }else{
                 this.$message({
                    message: res.msg,
                    type: 'error'
                });
            }
            this.loading.close();
        },
        // 上传失败
        imgerror(){
            this.$message({
                type: "error",
                message:'上传失败'
            })
            this.loading.close();
        },
        // 上传中
        beforeAvatarUpload(){
            this.loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
    },
    mounted() {
        this.getculturalList()
        const pass = this.$cookies.get('pass')
        if(pass == this.editPass){
            this.idEditpass = true
        }else{
             this.idEditpass = false
        }
        
    },
}
</script>
<style lang='scss' scoped>
    @import './index.scss';
</style>